<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Внимание
    </div>
    <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem; margin-bottom: 1.5rem">
      До конца занятия осталось менее 5 минут!
    </p>
    <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
      <div
          class="ytm-blue-button ytm-default-text"
          style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
          @click="$emit('reset_modal')"
      >
        Понятно
      </div>
    </div>
  </Modal>
</template>

<script>
import "@/styles/style.css";
import Modal from "@/components/redesign/Modal";

export default {
  name: 'WarningModal',
  components: {Modal},
};
</script>

<style scoped>

</style>