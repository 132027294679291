<template>
    <div>
        <div v-if="isSocketReady && !!lesson">
            <div v-if="lessonStatus === lessonStatuses.BEFORE" style="width: 100%; text-align: center">
                <div style="display: flex; justify-content: center; min-height: 2rem">
                    <DefaultTip v-if="lesson.roleId === roleIds.TEACHER" style="text-align: left; max-width: 800px">
                        <p class="ytm-default-text" style="font-weight: 600; line-height: 130%; color: #FFFFFF; font-size: 0.9rem; margin-bottom: 0.45rem; max-width: 700px">
                            После начала урока вы сможете использовать интерактивные материалы YouTeachMe – онлайн-доски, совместный текстовый редактор и многое другое.
                        </p>
                        <a class="ytm-default-text" style="font-weight: 700; color: #FFFFFF; font-size: 0.9rem" href="/tutorial" target="_blank" rel="noopener noreferer">
                            Узнать больше
                            <svg style="position: relative; top: 0.2rem; width: 0.9rem; height: 1rem" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 13.3679C0.895431 13.3679 8.19842e-08 14.2634 0 15.3679C-8.19842e-08 16.4725 0.89543 17.3679 2 17.3679L2 13.3679ZM33.1296 16.7821C33.9106 16.0011 33.9106 14.7348 33.1296 13.9537L20.4016 1.22579C19.6206 0.444737 18.3543 0.444737 17.5732 1.22579C16.7922 2.00683 16.7922 3.27316 17.5732 4.05421L28.8869 15.3679L17.5732 26.6816C16.7922 27.4627 16.7922 28.729 17.5732 29.5101C18.3543 30.2911 19.6206 30.2911 20.4016 29.5101L33.1296 16.7821ZM2 17.3679L31.7153 17.3679L31.7153 13.3679L2 13.3679L2 17.3679Z" fill="white"/>
                            </svg>
                        </a>
                    </DefaultTip>
                </div>
                <p class="ytm-default-text" style="font-size: 1.25rem; font-weight: 400; margin-top: 2rem; margin-bottom: 2rem">
                    Занятие ещё не началось, но вы можете подготовиться к нему! 💪
                </p>
            </div>
            <Materials
                :roomId="lesson.materialsRoomId"
                :isRoomAdmin="lesson.roleId === roleIds.TEACHER"
                :active="lessonStatus === lessonStatuses.RUNNING"
                :useDrafts="lessonStatus < lessonStatuses.AFTER && lesson.roleId === roleIds.TEACHER"
                :showDraftsButtonAtHead="lessonStatus === lessonStatuses.BEFORE && lesson.roleId === roleIds.TEACHER"
                :showDraftsCircleButton="lessonStatus === lessonStatuses.RUNNING && lesson.roleId === roleIds.TEACHER"
                :canPostNewMaterials="lessonStatus === lessonStatuses.RUNNING && lesson.roleId === roleIds.TEACHER"
                :messageIfNoMaterials="messageIfNoMaterials"
                :typesConfig="typesConfig"
                style="margin-bottom: 50vh"
            />
        </div>
        <MaterialsSkeletons v-else/>
    </div>
</template>

<script>
import MaterialsSkeletons from "@/components/MaterialsV2/MaterialsSkeletons.vue";
import Materials from "@/components/MaterialsV2/Materials.vue";
import lessonStatuses from "@/util/lesson_statuses";
import roleIds from "@/util/roles";
import DefaultTip from "@/components/redesign/Tips/DefaultTip.vue";
import {computed, ref} from "vue";
import {useStore} from "vuex";

export default {
    name: 'MaterialsWrapperOnLesson',
    components: {DefaultTip, Materials, MaterialsSkeletons},
    setup() {
        const store = useStore();
        const isSocketReady = computed(() => store.getters['ws/socketReady']);
        const lesson = computed(() => store.getters['lesson/lesson']);
        const lessonStatus = computed(() => store.getters['lesson/lessonStatus']);
        const messageIfNoMaterials = computed(() => {
            switch (lessonStatus.value) {
                case lessonStatuses.RUNNING:
                    return 'Занятие&nbspначалось, но&nbspпока&nbspне&nbspприкреплено ни&nbspодного&nbspматериала';
                case lessonStatuses.AFTER:
                    return 'Занятие&nbspзавершено, материалы не&nbspбыли&nbspприкреплены';
                default:
                    return '';
            }
        });

        const typesConfig = ref({
            ctext: {
                name: 'Совместный текст',
                isEditable: computed(() => lessonStatus.value === lessonStatuses.RUNNING),
            },
            board: { name: 'Онлайн-доска' },
            code: { name: 'Окно с кодом' },
            images: { name: 'Изображения' },
            test: { name: 'Тест' },
        });

        return { isSocketReady, lesson, lessonStatus, messageIfNoMaterials, typesConfig };
    },
    data() {
        return { lessonStatuses, roleIds };
    },
};
</script>

<style scoped>

</style>