<template>
  <Modal @reset_modal="$emit('reset_modal')">
    <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
      Начать занятие
    </div>
    <div v-if="tariffId === -1">
      <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
        Чтобы начать занятие, нужно выбрать тариф
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="$router.push('/account?section=tariff')"
        >
          К выбору тарифа
        </div>
      </div>
    </div>
    <div v-else>
      <p class="ytm-default-text" style="font-size: 1.25rem; margin-bottom: 1.5rem">
        Перед началом занятия убедитесь, что выставлена актуальная продолжительность:
      </p>
      <InputGroup class="ytm-modal-input" v-model="duration" type="number" title="Продолжительность (в минутах)" placeholder="Продолжительность занятия в минутах" @input="onDurationInput" @keyup.enter="editLesson"/>
      <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
        {{errorMsg}}
      </p>
      <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
        <div
            class="ytm-blue-button ytm-default-text"
            style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
            @click="editLesson"
        >
          Начать занятие
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup";

export default {
  name: 'LessonStartModal',
  components: {Modal, InputGroup, DatetimeGroup},
  data() {
    return {
      duration: 80,
      editing: false,
      errorMsg: '',
    };
  },
  computed: {
    tariffId() {
      const userInfo = this.$store.getters['user_info/user_info'];
      if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
        return userInfo.tariffState.tariff.id;
      } else {
        return -1;
      }
    },
    lesson() {
      return this.$store.getters['lesson/lesson'];
    },
  },
  methods: {
    getDuration(lesson) {
      const diff = lesson.dtFinish - lesson.dtStart;
      return Math.round(diff / 1000 / 60);
    },
    onDurationInput() {
      this.duration = Math.max(0, Math.round(this.duration));
      this.duration = Math.min(60 * 12, Math.round(this.duration));
    },
    editLesson() {
      if (this.editing || !this.lesson) {
        return;
      }
      this.errorMsg = '';
      this.editing = true;
      const payload = {
        startNow: true,
        duration: this.duration,
      };
      this.$store.dispatch('auth/autoLogin').then(() => {
        axios.put(
            SERVICE_MAIN_URI + '/lesson',
            payload,
            {
              headers: authHeader(),
              params: {
                  courseId: this.$route.params.courseLink,
                  lessonId: this.$route.params.lessonId,
              },
            },
        ).then(resp => {
          this.editing = false;
          this.$emit('lesson_started');
          this.$emit('reset_modal');
        }).catch(err => {
          if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
            this.errorMsg = err.response.data.message.slice('tariffError: '.length);
          } else {
            this.errorMsg = 'Упс, не удалось начать занятие';
            console.error(err);
          }
          this.editing = false;
        });
      });
    },
  },
  mounted() {
    if (this.lesson) {
      this.duration = this.getDuration(this.lesson);
    }
  },
};
</script>

<style scoped>
.ytm-modal-input {
  margin-bottom: 1.25rem;
}
</style>