<template>
    <div
            class="ytm-transparent-button ytm-no-hover ytm-default-text"
            style="position: relative; border-color: #07A8F0"
    >
        <VueCountdown
                class="ytm-default-text"
                :time="lesson.finishedAt - new Date()"
                :transform="extendToTwoDigits"
                :interval="1000"
                v-slot="{hours, minutes, seconds}"
                style="color: #07A8F0; font-weight: 600"
        >
            {{hours}}:{{minutes}}:{{seconds}}
        </VueCountdown>
        <div v-if="isAbleToEditLesson && !editing" class="ytm-prolong">
            <p class="ytm-default-text ytm-prolong-button" @click="addTime(1)">
                +1
            </p>
            <p class="ytm-default-text ytm-prolong-button" @click="addTime(5)">
                +5
            </p>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/LessonPage/style.css";
import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";

export default {
    name: 'LessonClock',
    components: {VueCountdown},
    props: {
        lesson: {
            type: Object,
            required: true,
        },
        isAbleToEditLesson: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            editing: false,
        };
    },
    methods: {
        extendToTwoDigits(props) {
            const formattedProps = {};
            Object.entries(props).forEach(([key, value]) => {
                formattedProps[key] = key !== 'hours' && value < 10 ? `0${value}` : String(value);
            });
            return formattedProps;
        },
        addTime(minutes) {
            if (this.editing || !this.lesson) {
                return;
            }
            this.editing = true;
            const payload = {addTime: minutes};
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.put(
                    SERVICE_MAIN_URI + '/lesson',
                    payload,
                    {
                        headers: authHeader(),
                        params: {
                            courseId: this.$route.params.courseLink,
                            lessonId: this.$route.params.lessonId,
                        },
                    },
                ).then(resp => {
                    this.$store.dispatch(
                        'lesson/fetch',
                        {
                            courseLink: this.$route.params.courseLink,
                            lessonId: this.$route.params.lessonId,
                        },
                    ).then(() => {
                        this.editing = false;
                    }).catch(err => {
                        console.error(err);
                        this.editing = false;
                    });
                }).catch(err => {
                    this.editing = false;
                });
            });
        },
    },
};
</script>

<style scoped>
.ytm-no-hover {}
.ytm-no-hover:hover {
    transform: none;
}
.ytm-prolong {
    position: absolute;
    left: 0;
    bottom: -1.1rem;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 0.3rem;
}
.ytm-prolong-button {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: #07A8F0;
    padding: 0.2rem 0.6rem;
    border-radius: 0.5rem;
    transition: 0.2s ease-in-out;
    opacity: 0;
}
.ytm-prolong-button:hover {
    transform: scale(1.13);
}
.ytm-lesson-meta:hover .ytm-prolong-button {
    opacity: 1;
}
</style>