<template>
    <div class="ytm-container">
        <LessonSettingsModal v-if="showLessonSettingsModal" @reset_modal="showLessonSettingsModal = false" @lesson_updated="getLesson" @lesson_deleted="onLessonDeleted"/>
        <LessonStartModal v-if="showStartLessonModal" @reset_modal="showStartLessonModal = false" @lesson_started="getLesson"/>
        <LessonFinishModal v-if="showFinishLessonModal" @reset_modal="showFinishLessonModal = false" @lesson_finished="getLesson"/>
        <WarningModal v-if="showWarningModal" @reset_modal="showWarningModal = false"/>
        <div class="ytm-lesson-meta">
            <Navbar/>
            <div style="margin-top: 1.75rem; margin-bottom: 2.25rem">
                <div v-if="loading">
                    <div class="ytm-course-breadcrumb-text skeleton skeleton-text-default" style="margin-bottom: 0.5rem"/>
                    <div class="ytm-lesson-head-wrapper skeleton skeleton-text-big"/>
                </div>
                <div v-else>
                    <div style="display: flex">
                        <div class="ytm-course-breadcrumb" @click="$router.push(`/course/${courseLink}`)">
                            <p class="ytm-default-text ytm-course-breadcrumb-text">
                                {{course.title}}
                            </p>
                            <p class="ytm-default-text ytm-course-breadcrumb-text">
                                &nbsp/
                            </p>
                        </div>
                    </div>
                    <div class="ytm-lesson-head-wrapper">
                        <svg viewBox="0 0 21 35" fill="none" xmlns="http://www.w3.org/2000/svg"
                             class="ytm-lesson-head-icon"
                             style="margin-left: -0.5rem; margin-right: 0.75rem; height: 1.5rem; top: 0.45rem"
                             @click="goBack"
                        >
                            <path d="M17.5 3L3 17.5L17.5 32" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p class="ytm-default-text ytm-head-text">
                            {{lesson.topic}}
                        </p>
                        <svg v-if="isAbleToEditLesson" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-lesson-head-icon" @click="showLessonSettingsModal = true" style="margin-left: 0.75rem">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9248 2.73744C21.1003 -0.912479 15.8997 -0.91248 15.0753 2.73744C14.5409 5.10296 11.8264 6.22736 9.77585 4.93252C6.61199 2.93462 2.93462 6.61199 4.93252 9.77585C6.22736 11.8264 5.10296 14.5409 2.73744 15.0753C-0.912479 15.8997 -0.91248 21.1003 2.73744 21.9248C5.10296 22.4591 6.22736 25.1737 4.93252 27.2242C2.93462 30.388 6.61199 34.0654 9.77585 32.0675C11.8264 30.7727 14.5409 31.8971 15.0753 34.2626C15.8997 37.9125 21.1003 37.9125 21.9248 34.2626C22.4591 31.8971 25.1737 30.7727 27.2242 32.0675C30.388 34.0654 34.0654 30.388 32.0675 27.2242C30.7727 25.1737 31.8971 22.4591 34.2626 21.9248C37.9125 21.1003 37.9125 15.8997 34.2626 15.0753C31.8971 14.5409 30.7727 11.8264 32.0675 9.77585C34.0654 6.61199 30.388 2.93462 27.2242 4.93252C25.1737 6.22736 22.4591 5.10296 21.9248 2.73744ZM18.5 26C22.6421 26 26 22.6421 26 18.5C26 14.3579 22.6421 11 18.5 11C14.3579 11 11 14.3579 11 18.5C11 22.6421 14.3579 26 18.5 26Z" fill="black"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="ytm-lesson-tabs">
                <div style="flex-grow: 1">
                    <div class="ytm-tabs-titles">
                        <div
                            :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'materials'}"
                            @click="selectedTab = 'materials'; isChatFocused = false"
                        >
                            Материалы
                        </div>
                        <div
                            :class="{'ytm-tab ytm-default-text ytm-tab-title': true, 'ytm-tab-selected': selectedTab === 'chat'}"
                            @click="selectedTab = 'chat'; isChatFocused = true"
                        >
                            Чат
                        </div>
                    </div>
                </div>
                <div v-if="!loading">
                    <div
                        v-if="isAbleToStartLesson"
                        class="ytm-black-button ytm-default-text"
                        style="background: #07A8F0; color: #FFFFFF; font-weight: 600"
                        @click="showStartLessonModal = true"
                    >
                        Начать занятие
                    </div>
                    <div v-if="lessonStatus === lessonStatuses.RUNNING" class="ytm-lesson-buttons">
                        <LessonClock :lesson="lesson" :isAbleToEditLesson="isAbleToEditLesson"/>
                        <div
                            v-if="isAbleToFinishLesson"
                            class="ytm-transparent-button ytm-default-text"
                            style="border-color: #F55A5A; color: #F55A5A; font-weight: 600; white-space: nowrap"
                            @click="showFinishLessonModal = true"
                        >
                            Завершить занятие
                        </div>
                    </div>
                    <div v-if="lessonStatus === lessonStatuses.AFTER">
                        <div
                            class="ytm-transparent-button ytm-default-text"
                            style="border-color: #9E9E9E; color: #626262; font-weight: 600; white-space: nowrap"
                        >
                            Завершено
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="selectedTab === 'materials'" style="margin-bottom: 80vh">
            <MaterialsWrapperOnLesson/>
        </div>
        <div v-show="selectedTab === 'chat'" style="margin-bottom: 10vh">
            <Chat
                v-if="lesson && isSocketReady"
                :chat_id="'course_' + $route.params.courseLink"
            />
            <div v-else>
                <div class="skeleton" style="width: 100%; height: 60vh; border-radius: 1rem; margin-bottom: 2rem"/>
                <div class="skeleton" style="width: 100%; height: 2.5rem; border-radius: 0.5rem; margin-bottom: 2rem"/>
            </div>
        </div>
    </div>
</template>

<script>
import "@/styles/style.css";
import "@/components/CoursePageV2/skeletons.css";
import "@/components/LessonPage/style.css";
import lessonStatuses from "@/util/lesson_statuses";
import roleIds from "@/util/roles";
import Navbar from "@/components/redesign/Navbar.vue";
import {provide, ref} from "vue";
import LessonSettingsModal from "@/components/LessonPage/modals/LessonSettingsModal.vue";
import LessonStartModal from "@/components/LessonPage/modals/LessonStartModal.vue";
import LessonFinishModal from "@/components/LessonPage/modals/LessonFinishModal.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import WarningModal from "@/components/LessonPage/modals/WarningModal.vue";
import MaterialsWrapperOnLesson from "@/components/LessonPage/MaterialsWrapperOnLesson.vue";
import Chat from "@/components/redesign/Chat/Chat.vue";
import LessonClock from "@/components/LessonPage/LessonClock.vue";

export default {
    name: 'LessonPage',
    components: {
        LessonClock,
        MaterialsWrapperOnLesson, Chat, WarningModal, VueCountdown, LessonFinishModal,
        LessonStartModal, LessonSettingsModal, Navbar,
    },
    computed: {
        course() {
            return this.$store.getters['course/course'];
        },
        lesson() {
            return this.$store.getters['lesson/lesson'];
        },
        lessonStatus() {
            return this.$store.getters['lesson/lessonStatus'];
        },
        loading() {
            return this.$store.getters['lesson/loading'] || this.$store.getters['course/loading'] || this.$store.getters['user_info/loading']
                || !this.$store.getters['lesson/lesson'];
        },
        isSocketReady() {
            return this.$store.getters['ws/socketReady'];
        },
        isAbleToEditLesson() {
            return this.lesson && this.lesson.roleId === roleIds.TEACHER;
        },
        isAbleToStartLesson() {
            return this.lesson && this.lesson.roleId === roleIds.TEACHER && this.lessonStatus === lessonStatuses.BEFORE;
        },
        isAbleToFinishLesson() {
            return this.lesson && this.lesson.roleId === roleIds.TEACHER && this.lessonStatus === lessonStatuses.RUNNING;
        },
    },
    watch: {
        isSocketReady: {
            handler(ready) {
                if (ready) {
                    this.$store.dispatch('ws/refocusLesson', {lS: this.courseLink + ':' + this.lessonId});
                }
            },
        },
    },
    setup() {
        const isChatFocused = ref(false);
        provide('is_chat_focused', isChatFocused);
        return {isChatFocused};
    },
    data() {
        return {
            lessonStatuses,
            roleIds,
            courseLink: '',
            lessonId: 0,
            checkerForFinish: null,
            selectedTab: 'materials',
            showLessonSettingsModal: false,
            showStartLessonModal: false,
            showFinishLessonModal: false,
            warningModalShown: false,
            showWarningModal: false,
        };
    },
    methods: {
        goBack() {
            const pathBack = this.prevRoute.href || '/course/' + this.courseLink;
            this.$router.push(pathBack);
        },
        getLesson() {
            this.$store.dispatch(
                'lesson/fetch',
                {
                    courseLink: this.courseLink,
                    lessonId: this.lessonId,
                },
            ).catch(err => {
                console.error(err);
            });
        },
        onLessonDeleted() {
            this.$router.push('/course/' + this.courseLink);
        },
        runCheckIfLessonFinished() {
            const checker = () => {
                if (this.lessonStatus === lessonStatuses.AFTER) {
                    this.killCheckerForFinish();
                    return;
                }
                const now = new Date();
                if (this.lesson && this.lessonStatus === lessonStatuses.RUNNING) {
                    if (this.lesson.finishedAt < now) {
                        this.$store.dispatch('lesson/actualizeLessonStatus', lessonStatuses.AFTER);
                        this.killCheckerForFinish();
                        return;
                    }
                    if (this.lesson.finishedAt - now < 5 * 60 * 1000 && !this.warningModalShown) {
                        this.warningModalShown = true;
                        this.showWarningModal = true;
                    }
                }
            };
            checker();
            this.checkerForFinish = setInterval(checker, 1000);
        },
        killCheckerForFinish() {
            if (this.checkerForFinish) {
                clearInterval(this.checkerForFinish);
                this.checkerForFinish = null;
            }
        },
        onNewSocketMessage(t, payload) {
            if (t === 4002) {
                this.getLesson();
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next(vm => { vm.prevRoute = from });
    },
    mounted() {
        this.courseLink = this.$route.params.courseLink;
        this.lessonId = Number(this.$route.params.lessonId);
        this.$store.dispatch('ws/initialize').then(() => {
            this.$store.dispatch('ws/registerLesson', this);
            if (this.isSocketReady) {
                this.$store.dispatch('ws/refocusLesson', {lS: this.courseLink + ':' + this.lessonId});
            }
        }).catch(err => {
            console.error(err);
        });
        this.runCheckIfLessonFinished();
        this.getLesson();
        this.$store.dispatch('course/fetch', this.courseLink).catch(err => {
            console.error(err);
        });
        this.$store.dispatch('user_info/fetchUserInfo').catch(err => {
            console.error(err);
        });
    },
    beforeUnmount() {
        this.killCheckerForFinish();
        this.$store.dispatch('ws/refocusLesson', {lS: ''});
        this.$store.dispatch('ws/unregisterLesson', this);
        this.$store.dispatch('lesson/clear');
        this.$store.dispatch('course/clear');
        this.$store.dispatch('ws/closeConnection');
    },
};
</script>

<style scoped>

</style>