<template>
    <Modal @reset_modal="$emit('reset_modal')">
        <div class="ytm-default-text" style="font-size: 1.75rem; font-weight: 600; text-align: center; margin-bottom: 1.75rem">
            Редактировать занятие
        </div>
        <div v-if="tariffId === -1">
            <p class="ytm-default-text" style="text-align: center; font-size: 1.25rem">
                Чтобы изменить настройки занятия, нужно выбрать тариф
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="$router.push('/account?section=tariff')"
                >
                    К выбору тарифа
                </div>
            </div>
        </div>
        <div v-else>
            <div :class="{'ytm-inactive': wantToDeleteLesson}">
                <div class="ytm-fields" v-if="formData.dtStart">
                    <InputGroup class="ytm-modal-input" v-model="formData.topic" title="Тема" placeholder="Тема занятия" :focus="true" @keyup.enter="editLesson"/>
                    <DatetimeGroup class="ytm-modal-input" v-model="formData.dtStart" title="Планируемые дата и время начала"/>
                    <InputGroup class="ytm-modal-input" v-model="duration" type="number" title="Продолжительность (в минутах)" placeholder="Продолжительность занятия в минутах" @input="onDurationInput" @keyup.enter="editLesson"/>
                </div>
            </div>
            <Checkbox v-model="wantToDeleteLesson" id="remove" title="Удалить занятие" @update:modelValue="errorMsg = ''"/>
            <p v-if="errorMsg" class="ytm-default-text" style="margin-top: 1rem; margin-bottom: -1rem; text-align: center; color: red">
                {{errorMsg}}
            </p>
            <div style="margin-top: 2rem; width: 100%; display: flex; justify-content: center">
                <div
                    v-if="!wantToDeleteLesson"
                    class="ytm-blue-button ytm-default-text"
                    style="font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="editLesson"
                >
                    Сохранить изменения
                </div>
                <div
                    v-else
                    class="ytm-blue-button ytm-default-text"
                    style="background-color: #F55A5A; font-size: 0.9rem; color: #FFFFFF; font-weight: 600"
                    @click="deleteLesson"
                >
                    Удалить
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import "@/components/redesign/Form/forms.css";
import Modal from "@/components/redesign/Modal";
import InputGroup from "@/components/redesign/Form/InputGroup";
import axios from "axios";
import {SERVICE_MAIN_URI} from "@/util/api-host";
import {authHeader} from "@/util/auth-header";
import DatetimeGroup from "@/components/redesign/Form/DatetimeGroup";
import Checkbox from "@/components/redesign/Form/Checkbox.vue";

export default {
    name: 'LessonSettingsModal',
    components: {Checkbox, Modal, InputGroup, DatetimeGroup},
    data() {
        return {
            formData: {
                topic: '',
                dtStart: null,
                dtFinish: null,
            },
            duration: 80,
            wantToDeleteLesson: false,
            editing: false,
            errorMsg: '',
        };
    },
    computed: {
        tariffId() {
            const userInfo = this.$store.getters['user_info/user_info'];
            if (userInfo && userInfo.tariffState && userInfo.tariffState.tariff) {
                return userInfo.tariffState.tariff.id;
            } else {
                return -1;
            }
        },
        lesson() {
            return this.$store.getters['lesson/lesson'];
        },
    },
    methods: {
        getDuration(lesson) {
            const diff = lesson.dtFinish - lesson.dtStart;
            return Math.round(diff / 1000 / 60);
        },
        onDurationInput() {
            this.duration = Math.max(0, Math.round(this.duration));
            this.duration = Math.min(60 * 12, Math.round(this.duration));
        },
        editLesson() {
            if (this.editing || !this.lesson) {
                return;
            }
            this.formData.topic = this.formData.topic.trim();
            if (!this.formData.topic) {
                this.errorMsg = 'Укажите, пожалуйста, тему занятия';
                return;
            }
            this.errorMsg = '';
            this.editing = true;
            let payload = this.formData;
            payload.dtFinish = new Date(payload.dtStart);
            payload.dtFinish.setTime(payload.dtFinish.getTime() + this.duration * 60 * 1000);
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.put(
                    SERVICE_MAIN_URI + '/lesson',
                    payload,
                    {
                        headers: authHeader(),
                        params: {
                            courseId: this.$route.params.courseLink,
                            lessonId: this.$route.params.lessonId,
                        },
                    },
                ).then(resp => {
                    this.$emit('lesson_updated');
                    this.editing = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    if (err.response && err.response.data.message && err.response.data.message.startsWith('tariffError:')) {
                        this.errorMsg = err.response.data.message.slice('tariffError: '.length);
                    } else {
                        this.errorMsg = 'Упс, не удалось сохранить настройки занятия';
                        console.error(err);
                    }
                    this.editing = false;
                });
            });
        },
        deleteLesson() {
            if (this.editing || !this.lesson) {
                return;
            }
            this.$store.dispatch('auth/autoLogin').then(() => {
                axios.delete(
                    SERVICE_MAIN_URI + '/lesson',
                    {
                        headers: authHeader(),
                        params: {
                            courseId: this.$route.params.courseLink,
                            lessonId: this.$route.params.lessonId,
                        },
                    },
                ).then(resp => {
                    this.$emit('lesson_deleted');
                    this.editing = false;
                    this.$emit('reset_modal');
                }).catch(err => {
                    this.errorMsg = 'Упс, не удалось удалить занятие';
                    this.editing = false;
                });
            });
        },
    },
    mounted() {
        if (this.lesson) {
            this.formData.topic = this.lesson.topic;
            this.formData.dtStart = new Date(this.lesson.dtStart);
            this.formData.dtFinish = new Date(this.lesson.dtFinish);
            this.duration = this.getDuration(this.lesson);
        } else {
            this.formData.dtStart = new Date();
            this.duration = 80;
        }
    },
};
</script>

<style scoped>
.ytm-modal-input {
    margin-bottom: 1.25rem;
}
</style>